.contact-section {
    background-color: rgb(249, 248, 247);
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-entries {
  margin-top: 20px;
  margin-right: none;
  margin-left: none;
  display: flex;
  align-items: center;
  max-width: 1200px;
}

.contact-card {
  background-color: white;
  text-align: center;
  width: 280px; /* Each category takes up one-third of the screen width */
  height: 380px;
  margin-left: 2vw;
  margin-right: 2vw;
  padding-top: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.contact-picture {
  width: 130px;
  border-radius: 65px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.contact-card {
  
}

.contact-card h2 { /* Name */
  font-family: 'SF-Pro-Text-Thin', 'helvetica';
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(0, 0, 0);
}

.contact-card h3 {
  font-family: 'SF-Pro-Text-Thin', 'helvetica';
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: rgb(41, 41, 41);
}

.contact-card p { /* email */
  font-family: 'SF-Pro-Regular', 'helvetica';
  font-size: 14px;
  color: rgb(78, 78, 78);
  margin-top: 5px;
}

.linkedin-logo {
  margin-top: 25px;
  width: 100px;
  height: 35px;
  border-radius: 5px;
}

.general-inq-statement {
  font-family: 'SF-Pro-Text-Thin', 'helvetica';
  padding-top: 50px;
  font-size: 16px;
  color: rgb(104, 104, 104); 
}

  