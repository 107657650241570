header {
  /*background-color: rgba(77, 41, 151);*/
  /*background: linear-gradient(to top, rgba(135, 0, 193), rgb(124, 66, 241));*/
  background: rgba(44, 11, 61, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
  
.header-content {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

  /* Style for the left-buttons container */
.left-buttons {
    padding-left: 30px;
    display: flex;
    align-items: center; /* Vertically align the buttons */
  }

.route-links {
  text-decoration: none;
  background: none;
  color: rgb(253, 243, 98);
  border: none;
  padding: 0; 
  font-family: 'SF-Pro-Text-Thin', 'Arial';
  font-size: 19px;
  margin-right: 30px; /* Adjust spacing between buttons */
  transition: color 0.3s;
}

.route-links:hover {
  color: rgb(144, 140, 0);
  cursor: pointer;
}



.pc-logo {
  max-width: 90px; /* Adjust the max-width of your logo */
  margin-left: 2vw;
}

.dividing-line {
  height: 1px; /* Adjust the height of the line as needed */
  background: linear-gradient(to right, rgba(77, 41, 151, 0.3), rgb(134, 121, 0), rgba(77, 41, 151, 0.3));
}