.container-style {
    height: 100vh;
    background: radial-gradient(circle, rgba(160, 13, 223, 0.6), rgba(77, 41, 151, 0.6));
  }
  
  #tsparticles { 
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    z-index: 0;
    box-shadow: 0px 5px 20px rgb(63, 5, 97);
  }

  .HeroContent {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 60vw;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 20px;
    color: white;
    padding-top: 5vh;
  }

  /* Style for the content on the right */
  .content {
    width: 20vw;
    height: 20vw;
    padding: 20px;
    border-radius: 4vw;
    border: 1px solid rgba(250, 247, 65, 0.3);
    background: linear-gradient(to top, #3A134D, #712493);
    box-shadow: 0px 5px 5px rgba(0,0,0,0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  
  }
  
  /* Style for the iPhone image */
  .iPhoneImage {
    height: 36vw;
    width: 18vw;
    z-index: 2;
    border-radius: 3vw;
    box-shadow: 0px 5px 20px rgb(0, 0, 0);
  }

  .appStoreImage {
    height: 3vw;
    width: 3vw;
    z-index: 2;;
  }