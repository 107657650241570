.tos-privacy-container {
    padding-top: 100px;
    padding-bottom: 50px;
    background: radial-gradient(circle, rgba(160, 13, 223, 0.6), rgba(77, 41, 151, 0.6));
}

.content-container {
    display: flex;
    flex-direction: column;
}

/* Title */
.content-container h1 {
    margin: 0;
    color: rgb(226, 198, 15);
    text-align: center;
    font-family: 'SF-Pro-Display-Thin', sans-serif;
    font-size: 34px;
    
}

/* Last Updated Date */
.content-container h3 {
    margin-top: 10px;
    margin-bottom: 20px;
    color: rgb(204, 178, 6);
    padding-bottom: 3vh;
    text-align: center;
    font-family: 'SF-Pro-Text-Thin', sans-serif;
    font-size: 24px;
}

.content-container h2 {
    text-align: start;
    color: rgb(255, 255, 255);
    margin: 0;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 0vh;
    padding-bottom: 5px;
}

.content-container p {
    text-align: start;
    color: rgb(255, 255, 255);
    margin-left: 22%;
    margin-right: 22%;
    padding-top: 0vh;
    padding-bottom: 5px;
}